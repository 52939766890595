import requests from "@/js/requests"
import {vuetifyConfig} from "@/plugins/vuetify";

function getfieldAttrInput(fieldAttributes) {
  let fieldAttrInput = {}
  if (typeof fieldAttributes != "undefined") {
    if (typeof fieldAttributes.fieldAttrInput != "undefined") {
      fieldAttrInput = fieldAttributes.fieldAttrInput
    }
  }
  return fieldAttrInput
}

function getFieldsAttributes(templateContent, field) {
  let attrs = {}
  if (typeof templateContent.fields[field] != "undefined") {
    attrs = templateContent.fields[field]
  }
  return attrs
}

function getdefaultFormHeader(result) {
  let formHeader = {}
  formHeader.id = "form" + getRandomInt(0, 10000)

  formHeader.action = result.action

  let className = "fields"
  if (
    typeof result.additionlFormAtts != "undefined" &&
    typeof result.additionlFormAtts.class != "undefined"
  ) {
    className = result.additionlFormAtts.class

    className = className + " fields"
  }
  formHeader.class = className
  formHeader.autocomplete = "off"
  formHeader.method = "post"
  //TODO: handeling request types
  formHeader.requestType = "ajax"
  formHeader.enctype = "multipart/form-data"
  if (typeof result.parseFunctionName != "undefined") {
    formHeader.parseFunctionName = result.parseFunctionName
  }
  //console.log(formHeader);
  return formHeader
}

function getfieldAttrRow(fieldAttributes, field) {
  let fieldAttrInput = {}
  if (typeof fieldAttributes != "undefined") {
    if (typeof fieldAttributes.fieldAttrRow != "undefined") {
      fieldAttrInput = fieldAttributes.fieldAttrRow
    }
    if (typeof fieldAttributes.fieldAttrRow != "undefined") {
      fieldAttrInput = {
        ...fieldAttrInput,
        ...fieldAttributes.fieldAttrRow,
      }
    }
  }
  if (typeof fieldAttrInput.id == "undefined") {
    fieldAttrInput.id = field + "Row"
  }

  return fieldAttrInput
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * @param $sentArray
 * @param $attributeIndex
 * @param $addedClass
 * @return array
 */
function addAdditionalClassToAttributes($sentArray, $attributeIndex, $addedClass) {
  let $attributesArray = []
  if (typeof $sentArray[$attributeIndex] != "undefined") {
    $attributesArray = $sentArray[$attributeIndex]
    if (typeof $sentArray[$attributeIndex]["class"] != "undefined") {
      let $class = $attributesArray["class"]
      $class = $class + " " + $addedClass
      $attributesArray["class"] = $class
    } else {
      $attributesArray["class"] = $addedClass
    }
  } else {
    $attributesArray["class"] = $addedClass
  }
  return $attributesArray
}

function findInArray(key, sourceArray) {
  // search in an array of key value pair and return the value found
  //Argument
  //key = the key to search for
  // sourceArray = the the key value pair array to search in
  // returned
  //the  value stored in the pair
  // called by setOnChangeAttributeValues
  var returnedValue = ""
  sourceArray.forEach(function (attrvalue) {
    if (attrvalue[0] == key) {
      returnedValue = attrvalue[1]
      return true
    }
  })
  return returnedValue
}

function isObjectArray(element) {
  // check if the given element is an array or an object
  //Arrguments
  // element= the checked element
  //return
  // true if object or array false if not
  // called by : getGroupedAttrsArray, replaceHttpAttributes, parseTestCaseObject, addEditedValues
  if (Array.isArray(element) || typeof element == "object") {
    return true
  } else {
    return false
  }
}

function getAvatarText(str) {
  var text = ""
  var arr = str.split(" ")
  for (let i = 0; i < arr.length; i++) {
    text += arr[i].substr(0, 1)
  }
  return text.slice(0, 2)
}

function arrayFindInArray(key, sourceArray) {
  // search in an array of key value pair and return the pair found
  //Argument
  //key = the key to search for
  // sourceArray = the the key value pair array to search in
  // returned
  //the found pair
  // called by getDiameterRequestAttrRow,getAttributesArray,getRadiusRequestAttrRow,getRadiusResponseAttrRow
  var returnedValue = ""
  if (typeof sourceArray != "undefined") {
    sourceArray.forEach((attrvalue) => {
      // $.each(sourceArray, function (attrindex, attrvalue) {
      if (attrvalue[0] == key) {
        returnedValue = attrvalue
        return true
      }
    })
  }
  return returnedValue
}

function getPlaceholder(fieldAttrInput) {
  let placeholder = " "
  if (
    fieldAttrInput != null &&
    typeof fieldAttrInput.placeholder != "undefined" &&
    fieldAttrInput.placeholder != ""
  ) {
    placeholder = fieldAttrInput.placeholder
  }
  return placeholder
}

function getNoDataLabel(fieldAttrInput, caller) {
  let noDataLabel = caller.$vuetify.noDataText
  if (
    fieldAttrInput != null &&
    typeof fieldAttrInput.noDataLabel != "undefined" &&
    fieldAttrInput.noDataLabel != ""
  ) {
    noDataLabel = fieldAttrInput.noDataLabel
  }
  return noDataLabel
}

function objectCustomSort(sortOrder, sourceObject) {
  //for single object!

  var objArray = []
  for (let key in sourceObject) {
    objArray.push({
      value: sourceObject[key],
      name: key,
    })
  }
  const customSort = ({ data, sortOrder, sortField }) => {
    const sortByObject = sortOrder.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index,
      }
    }, {})
    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]])
  }
  let newObjectArray = customSort({
    data: objArray,
    sortOrder,
    sortField: "name",
  })
  var newObject = newObjectArray.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.name]: item.value,
      }),
    {}
  )
  //console.log(newObject);
  return newObject
}

/**
 * @param $string
 * @return bool
 */

function checkJSON(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

/**
 * @param $string
 * @return bool
 */
function checkXml(val) {
  if (String(val).match(/^\s*<\?[a-z][\s\S]*>\s*$/i) != null) {
    return true
  } else {
    return false
  }
}

/**
 * @param $string
 * @return bool
 */
function isHTML(str) {
  let pattern = new RegExp(/(<([^>]+)>)/i)
  return pattern.test(str)
}

function getBlobContent(response) {
  var fileURL = window.URL.createObjectURL(new Blob([response.data]))
  console.log(response)
  let fileName = response.headers["content-disposition"].split("filename=")[1]
  fileName = fileName.replace(/['"]+/g, "") //removing double quote
  fileDirectDownload(fileURL, fileName)
}

function fileDirectDownload(fileURL, fileName) {
  var fileLink = document.createElement("a")
  fileLink.href = fileURL
  fileLink.setAttribute("download", fileName)
  document.body.appendChild(fileLink)
  fileLink.click()
}

function getFieldId(fieldAttrInput, templateContent, name) {
  let id
  if (
    fieldAttrInput != null &&
    typeof fieldAttrInput != "undefined" &&
    typeof fieldAttrInput.id != "undefined"
  ) {
    id = fieldAttrInput.id
  } else if (
    typeof templateContent != "undefined" &&
    typeof templateContent.additionlFormAtts != "undefined" &&
    typeof templateContent.additionlFormAtts["data-className"] != "undefined" &&
    typeof name != "undefined"
  ) {
    id = templateContent.additionlFormAtts["data-className"] + name
  } else if (
    typeof templateContent != "undefined" &&
    typeof templateContent.contentID != "undefined" &&
    typeof name != "undefined"
  ) {
    id = templateContent.contentID + "_" + name
  }
  return id
}

function trimString(string, length) {
  return string.length > length ? string.substring(0, length) + "..." : string
}

function objectSortByValue(objList) {
  return Object.keys(objList)
    .sort()
    .reduce((a, v) => {
      a[v] = objList[v]
      return a
    }, {})
}

function explorerActivation(row, caller) {
  let status = 0
  if (row.n_status == 0) {
    status = 1
  }
  let options = {
    function: "toggleExplorerStatus",
    n_id: row.n_id,
    n_id_key: row.n_id_key,
    status: status,
    dataType: "json",
    requestType: "ajax",
  }
  //let caller = this;
  requests
    .frameworkAxiosRequest({
      method: "POST",
      url: "serve.php?f=administration&f2=explorers",
      data: options,
    })
    .then(function (response) {
      // console.log(response);
      let statusType = "all"
      if (
        typeof caller.currentTable != "undefined" &&
        typeof caller.currentTable.selectedStatus != "undefined"
      ) {
        statusType = caller.currentTable.selectedStatus
      }
      caller.$root.$refs.explorerStatusFilter.getSelectedStatusData(statusType)
      // console.log(caller.currentTable.selectedStatus);
      if (response.status == "200") {
        caller.$notify({
          group: "foo",
          text: "Successfully updated",
          duration: 1000,
          type: "success",
          speed: 600,
        })
      } else {
        caller.$notify({
          group: "foo",
          text: "Something Wrong! Can't update, try again!",
          duration: 1000,
          type: "warning",
          speed: 600,
        })
      }
    })
    .catch(function (error) {
      //handle error
      console.log(error)
    })
}

function refreshBody(caller, URL) {
  let path = caller.$route.fullPath
  path = path.replace("/", "")
  let saveLifeString = Math.random().toString(36).substring(7)
  caller.$router
    .push(path + "&" + saveLifeString)
    .catch(() => {
      caller.$router.go()
    })
    .then(() => {
      history.pushState({}, null, URL)
    })
}

function setAndUpdateTable(currentTable, newTable, caller) {
  if (currentTable.id == newTable.id) {
    if (currentTable.dateChange) {
      caller.$set(newTable, "dateChange", currentTable.dateChange)
      caller.$set(newTable, "untilTime", currentTable.untilTime)
      caller.$set(newTable, "fromTime", currentTable.fromTime)
      caller.$set(
        newTable.timepickerSelectOption,
        "value",
        currentTable.timepickerSelectOption.value
      )
    }

    caller.$set(newTable, "sortBy", currentTable.sortBy)
    caller.$set(newTable, "sortDesc", currentTable.sortDesc)
    caller.$set(newTable, "searchValue", currentTable.searchValue)
  }
}
function showSeconds(value) {
  return value == 1 ? value + " Second" : value + " Seconds"
}
function convertMs(value) {
  return (value / 1000000).toFixed(2) + " ms"
}

function convertSeconds(value) {
  return (value / 1000000000).toFixed(2) + " Seconds"
}

function showMbs(value) {
  return typeof value === "number" ? value.toFixed(2) + " Mbit/s" : ""
}

function convertMBits(value) {
  let cal = (value / 1000000).toFixed(2)
  let mbits = cal + " Mbit/s"
  if (parseFloat(cal) == 0) {
    mbits = value + " bit/s"
  }
  return mbits
}
function convertByte2MB(value, type = "b", iUnit = true) {
  // default binary "b" comes with "i" in units
  let typeVal = 1024
  if (type == "d") {
    typeVal = 1000
  }
  let units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  if (iUnit === true) {
    units = ["bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
  }
  let l = 0,
    n = parseInt(value, 10) || 0
  while (n >= typeVal && ++l) {
    n = n / typeVal
  }
  //n = Math.floor(n);
  //let result = (n.toFixed(n < 10 && l > 0 ? 2 : 1) + ' ' + units[l]);
  let result = n.toFixed(2) + " " + units[l]
  return result
}

function runTest(n_id, n_id_key, caller) {
  //console.log("Start parameters: ", caller.startParameters);
  caller.loading = true
  requests
    .frameworkAxiosRequest({
      method: "post",
      url:
        "serve.php?f=testing&f2=testsExecuteApi&function=exeuteandShowTestbyTestID&n_id=" +
        n_id +
        "&n_id_key=" +
        n_id_key,
      data: {
        startParameters: caller.startParameters,
      }, //headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
    })
    .then(function (response) {
      runTestCallBack(response, caller)
    })
    .catch(function (response) {
      //handle error
      console.log(response)
    })
}

function runTestCallBack(response, caller) {
  caller.loading = false
  caller.localLoadingDialog = false
  //console.log(response)
  if (
    typeof response.data.result.responseCode != "undefined" &&
    response.data.result.responseCode == "201"
  ) {
    caller.startParameters = []
    caller.parameterDialog = false
//    console.log(response.data.result.redirect)
    caller.$router.push(response.data.result.redirect).catch(() => {})
  } else if (
    typeof response.data.result.responseCode != "undefined" &&
    response.data.result.responseCode == "300"
  ) {
    caller.parameterDialog = true
    //console.log(response.data.result.body)
    caller.startParameters = response.data.result.body.startParameters
    caller.testNames = response.data.result.testNames
  } else {
    caller.showErrorDialog = true
    caller.parameterDialog = false
    caller.startParameters = []
  }
}

function ipString(ipV) {
  let ipStr = "IPv6"
  if (ipV == "ipv4") {
    ipStr = "IPv4"
  }
  return ipStr
}

function filterObjectArray(usedObject, searchString) {
  let query = searchString
  if (query == "") {
    return usedObject
  }
  let searchFunction = (haystack, search) => {
    return haystack.toLowerCase().includes(search.toLowerCase())
  }
  if (Array.isArray(usedObject)) {
    let returnedArray = []
    usedObject.forEach((key) => {
      if (typeof key == "object") {
        let result = filterObjectArray(key, searchString)
        if (result) {
          returnedArray.push(result)
        }
      } else if (searchFunction(key, searchString)) {
        returnedArray.push(key)
      }
    })
    return returnedArray
  } else {
    let returnedObject = {}
    Object.keys(usedObject).forEach((key) => {
      if (typeof usedObject[key] == "object") {
        let result = filterObjectArray(usedObject[key], searchString)
        if (result) {
          returnedObject[key] = result
        }
      } else if (
        searchFunction(key, searchString) ||
        searchFunction(usedObject[key], searchString)
      ) {
        returnedObject[key] = usedObject[key]
      }
    })
    return returnedObject
  }
}

function objSortBy(property) {
  return function (a, b) {
    if (typeof a[property] !== "undefined" && typeof b[property] !== "undefined")
      return a[property].toLowerCase() < b[property].toLowerCase()
        ? -1
        : a[property].toLowerCase() > b[property].toLowerCase()
        ? 1
        : 0
  }
}

//sort obj: obj of obj convert into obj of array and sort it,again back to obj of obj! Note: special case only!
function sortObjectSpecial(data, property) {
  const arrOfObj = Object.values(data)
  let sortedArrOfObj = arrOfObj.sort(objSortBy(property))
  const arrayToObject = (arr) => {
    const res = {}
    arr.forEach((obj, index) => {
      res[index] = obj
    })
    return res
  }
  return arrayToObject(sortedArrOfObj)
}

function getExpectColor(row) {
  //console.log('type '+row['rawType'],'PType '+row['n_packetType'],'space '+row['c_space']);
  let className = ""
  if (row["c_space"] != null && row["c_space"].includes("sub")) {
    className = "expectColor--text"
  } else if (
    (row["rawType"] === "HTTP" || row["rawType"] === "RADIUS") &&
    row["n_packetType"] === "2"
  ) {
    className = "expectColor--text"
  }
  return className
}

function downloadFile(data, filename, type) {
  var file = new Blob([stripslashes(data)], { type: type })
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename)
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file)
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
    }, 0)
  }
}

function getStatusColor(status) {
  let colorCode = [0, 0, 0]
  if (status === "ok") {
    colorCode = [0, 128, 0]
  } else if (status === "error") {
    colorCode = [210, 50, 50]
  } else if (status === "warning") {
    colorCode = [255, 200, 150]
  }
  return "color:rgb(" + colorCode.join(",") + ")"
}

function stripslashes(str) {
  return (str + "").replace(/\\(.?)/g, function (s, n1) {
    switch (n1) {
      case "\\":
        return "\\"
      case "0":
        return "\u0000"
      case "":
        return ""
      default:
        return n1
    }
  })
}

function findSubstringOperator(nameString, operatorList) {
  var op = ""
  operatorList.forEach((operator) => {
    if (nameString.indexOf(operator) !== -1) {
      op = operator
    }
  })
  return op
}

function constructNameString(nameString, operatorList) {
  //console.log(nameString);
  const substring = findSubstringOperator(nameString, operatorList)
  let result = nameString
  // const substring = 'contains';
  if (substring !== "" && nameString.indexOf(substring) !== -1) {
    let resultArray = nameString.split(substring)
    result =
      resultArray[0] +
      '<span class="rule">' +
      substring +
      '</span> "' +
      resultArray[1].trimStart() +
      '": '
  } else {
    result += ": "
  }
  return result
}

function sortObjectByKeys(object) {
  return Object.fromEntries(
    Object.entries(object).sort(([k1], [k2]) => (k1.toLowerCase() < k2.toLowerCase() ? -1 : 1))
  )
}

function setupAttribute(attributes) {
  const oldFormatAttributes = []
  for (const attribute of attributes) {
    oldFormatAttributes.push([attribute.id, `${attribute.name} (${attribute.id})`, attribute.type])
  }
  return oldFormatAttributes
}

function radiusAttrNames(attributes) {
  const attr = []
  for (const attribute of attributes) {
    attr.push([attribute.id, attribute.name])
  }
  return attr
}

function truncateStr(string, limit) {
  if (string.length > limit) {
    string = string.substring(0, limit) + "..."
  }
  return string
}

function sortObjectMessage(cMessage, row) {
  var result = cMessage
  if (cMessage.length > 0 && row.rawType === "LDAP") {
    result = cMessage.slice().sort(objSortBy("name")) //slice must be present otherwise infinate loops issue comes
  }
  return result
}
export function SHA1(msg) {
  function rotate_left(n, s) {
    var t4 = (n << s) | (n >>> (32 - s))
    return t4
  }
  function cvt_hex(val) {
    var str = ""
    var i
    var v
    for (i = 7; i >= 0; i--) {
      v = (val >>> (i * 4)) & 0x0f
      str += v.toString(16)
    }
    return str
  }
  function Utf8Encode(string) {
    string = string.replace(/\r\n/g, "\n")
    var utftext = ""
    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n)
      if (c < 128) {
        utftext += String.fromCharCode(c)
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192)
        utftext += String.fromCharCode((c & 63) | 128)
      } else {
        utftext += String.fromCharCode((c >> 12) | 224)
        utftext += String.fromCharCode(((c >> 6) & 63) | 128)
        utftext += String.fromCharCode((c & 63) | 128)
      }
    }
    return utftext
  }
  var blockstart
  var i, j
  var W = new Array(80)
  var H0 = 0x67452301
  var H1 = 0xefcdab89
  var H2 = 0x98badcfe
  var H3 = 0x10325476
  var H4 = 0xc3d2e1f0
  var A, B, C, D, E
  var temp
  msg = Utf8Encode(msg)
  var msg_len = msg.length
  var word_array = new Array()
  for (i = 0; i < msg_len - 3; i += 4) {
    j =
      (msg.charCodeAt(i) << 24) |
      (msg.charCodeAt(i + 1) << 16) |
      (msg.charCodeAt(i + 2) << 8) |
      msg.charCodeAt(i + 3)
    word_array.push(j)
  }
  switch (msg_len % 4) {
    case 0:
      i = 0x080000000
      break
    case 1:
      i = (msg.charCodeAt(msg_len - 1) << 24) | 0x0800000
      break
    case 2:
      i = (msg.charCodeAt(msg_len - 2) << 24) | (msg.charCodeAt(msg_len - 1) << 16) | 0x08000
      break
    case 3:
      i =
        (msg.charCodeAt(msg_len - 3) << 24) |
        (msg.charCodeAt(msg_len - 2) << 16) |
        (msg.charCodeAt(msg_len - 1) << 8) |
        0x80
      break
  }
  word_array.push(i)
  while (word_array.length % 16 != 14) word_array.push(0)
  word_array.push(msg_len >>> 29)
  word_array.push((msg_len << 3) & 0x0ffffffff)
  for (blockstart = 0; blockstart < word_array.length; blockstart += 16) {
    for (i = 0; i < 16; i++) W[i] = word_array[blockstart + i]
    for (i = 16; i <= 79; i++) W[i] = rotate_left(W[i - 3] ^ W[i - 8] ^ W[i - 14] ^ W[i - 16], 1)
    A = H0
    B = H1
    C = H2
    D = H3
    E = H4
    for (i = 0; i <= 19; i++) {
      temp = (rotate_left(A, 5) + ((B & C) | (~B & D)) + E + W[i] + 0x5a827999) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 20; i <= 39; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0x6ed9eba1) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 40; i <= 59; i++) {
      temp =
        (rotate_left(A, 5) + ((B & C) | (B & D) | (C & D)) + E + W[i] + 0x8f1bbcdc) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    for (i = 60; i <= 79; i++) {
      temp = (rotate_left(A, 5) + (B ^ C ^ D) + E + W[i] + 0xca62c1d6) & 0x0ffffffff
      E = D
      D = C
      C = rotate_left(B, 30)
      B = A
      A = temp
    }
    H0 = (H0 + A) & 0x0ffffffff
    H1 = (H1 + B) & 0x0ffffffff
    H2 = (H2 + C) & 0x0ffffffff
    H3 = (H3 + D) & 0x0ffffffff
    H4 = (H4 + E) & 0x0ffffffff
  }
  temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4)
  return temp.toLowerCase()
}

function shaKey(msg) {
  return SHA1(msg + "saltKeyG$3")
}

function tunnelTag(additionalTunnelTag) {
  let arr = []
  let rows = 31
  let columns = 2
  let start = 0
  for (let i = 0; i < rows; i++) {
    start++
    arr[i] = []
    for (let j = 0; j < columns; j++) {
      arr[i][j] = start.toString()
    }
  }
  arr.unshift(["0", "None"])
  if (typeof additionalTunnelTag != "undefined") {
    arr.unshift(additionalTunnelTag)
  }
  return arr
}
function tunnelTagObject(additionalTunnelTag) {
  let arr = []
  let rows = 31
  for (let i = 0; i < rows; i++) {
    arr[i] = { id: `${i}`, name: `${i}` }
  }
  arr.unshift({ id: "0", name: "None" })
  if (typeof additionalTunnelTag != "undefined") {
    arr.unshift(additionalTunnelTag)
  }
  //console.log(arr);
  return arr
}
function isJson(item) {
  let value = typeof item !== "string" ? JSON.stringify(item) : item
  try {
    value = JSON.parse(value)
  } catch (e) {
    return false
  }
  return typeof value === "object" && value !== null
}
function jsonViewerValue(sentValue) {
  //make it json!
  if (typeof sentValue == "string") {
    sentValue = JSON.parse(sentValue)
  }
  return filterObjectArray(sentValue, this.localSearch)
}

function arrayMergeOnPosition(arr1, arr2, i = 0) {
  return arr1.slice(0, i).concat(arr2, arr1.slice(i))
}

function removeDuplicateAoA(arr) {
  const map = new Map()
  arr.forEach((x) => map.set(JSON.stringify(x), x))
  arr = [...map.values()]
  return arr
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && JSON.stringify(obj) === "{}"
}

function objectKeysToLowerCase(input) {
  if (typeof input !== "object") return input
  if (Array.isArray(input)) return input.map(objectKeysToLowerCase)
  return Object.keys(input).reduce(function (newObj, key) {
    let val = input[key]
    let newVal = typeof val === "object" && val !== null ? objectKeysToLowerCase(val) : val
    newObj[key.toLowerCase()] = newVal
    return newObj
  }, {})
}

const safeParseFromStorage = ({ key, defaultValue, storage }) => {
  try {
    return JSON.parse(storage.getItem(key))
  } catch (err) {
    if (err instanceof SyntaxError) {
      console.error(`Error parsing '${key}' from localStorage`, err)
      return defaultValue
    }
  }
}

const getByKey = (obj, key) => {
  if (!obj) {
    return null
  }
  try {
    return key.split(".").reduce((o, k) => o[k], obj)
  } catch (err) {
    if (err instanceof TypeError) {
      console.error(`Error getting '${key}' from object`, err)
      return null
    }
    throw err
  }
}

const setPropertyByPath = (obj, path, value) => {
  const keys = path.split('.');
  let current = obj;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!(keys[i] in current)) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }

  current[keys[keys.length - 1]] = value;
  return current
}

const parseIntOrDefault = (value, defaultValue) => {
  const parsed = parseInt(value, 10)
  return Number.isNaN(parsed) ? defaultValue : parsed
}

const capitalize = word =>
  word.charAt(0).toUpperCase()
  + word.slice(1)

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

function createBlueprint(obj) {
  if (Array.isArray(obj)) {
    return [];
  } else if (typeof obj === 'object' && obj !== null) {
    const blueprint = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === 'object') {
          blueprint[key] = createBlueprint(value);
        } else {
          blueprint[key] = null;
        }
      }
    }
    return blueprint;
  } else {
    return null;
  }
}

const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result); // Get Base64 result
    reader.onerror = (error) => reject(error);
  });
};


function base64ToBlob(base64, contentType = 'image/png') {
  const byteCharacters = atob(base64.split(',')[1]); // Decode Base64 part after the prefix
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

function tooltipSource(obj) {
  let imageSource = obj?.headerImage ? obj.headerImage : obj?.imgURL;
  if (imageSource?.indexOf("We will") == -1) {
    var height=  obj?.reHeight ? obj.reHeight : 45;
    var width =  obj?.reWidth ? obj.reWidth : 545;

    let ttImage = "<img src=" + `${imageSource}` + " height=" + height + " width=" + width + ">";
    if (imageSource?.indexOf('https://degust-storage') == -1) {
      ttImage = "<img src=" + `${window.location.origin}/${imageSource}` + " height=" + height + " width=" + width + ">"
    }
    return ttImage;
  } else if (obj?.tooltipText) {
    return obj.tooltipText;
  } else {
    return true;
  }
}

const secToMillisec = (sec) => sec * 1000;

const millisecToMicrosec = (millisec) => millisec * 1000;

const secToMicrosec = (sec) => millisecToMicrosec(secToMillisec(sec));


const deepMerge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (
      typeof source[key] === "object" &&
      source[key] !== null &&
      !Array.isArray(source[key])
    ) {
      if (!target[key] || typeof target[key] !== "object") {
        target[key] = {};
      }
      deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};

function convertToCsv(items, csvHeaders) {
  const DELIMITER = ";";
  
  const sanitizeValue = (value) => {
    if (value === undefined || value === null) return "";
    
    let stringValue = String(value)
      .replaceAll("\r", " ")
      .replaceAll("\n", " ")
      .replaceAll("\\r", " ")
      .replaceAll("\\n", " ")
      .replaceAll(/\s+/g, " ");
    
    if (stringValue.includes(",") && 
        stringValue[0] !== '"' && 
        stringValue[stringValue.length - 1] !== '"') {
      stringValue = `"${stringValue}"`;
    }
    
    return stringValue;
  };

  const processRow = (item) => {
    return csvHeaders
      .map(header => {
        const rawValue = header.formatter !== undefined 
          ? header.formatter(getByKey(item, header.key), item)
          : getByKey(item, header.key);
          
        return sanitizeValue(rawValue) + DELIMITER;
      })
      .join("");
  };

  const headerRow = csvHeaders
    .map(header => header.name + DELIMITER)
    .join("");

  return [
    headerRow,
    ...items.map(item => processRow(item))
  ].join("\n");
}

function findAndReplace(list, newObject, predicateFn) {
  if (!Array.isArray(list) || !predicateFn) {
    return list;
  }

  const index = list.findIndex((item, index) => predicateFn(item, index));
  
  if (index === -1) {
    return list;
  }

  return [
    ...list.slice(0, index),
    newObject,
    ...list.slice(index + 1)
  ];
}

function findAndRemove(list, predicateFn) {
  if (!Array.isArray(list) || !predicateFn) {
    return list;
  }

  const index = list.findIndex((item, index) => predicateFn(item, index));
  
  if (index === -1) {
    return list;
  }

  return [
    ...list.slice(0, index),
    ...list.slice(index + 1)
  ];
}

function getContrastTextColor(inputColor) {
  const bgColor = vuetifyConfig.theme.themes.light[inputColor];
  // Convert HEX to RGB
  let r = parseInt(bgColor.substring(1, 3), 16);
  let g = parseInt(bgColor.substring(3, 5), 16);
  let b = parseInt(bgColor.substring(5, 7), 16);

  let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  return luminance > 0.5 ? "rgba(0,0,0,0.5)" : "rgba(255,255,255,0.7)";
}

export {
    getContrastTextColor,
    findAndReplace,
    findAndRemove,
    tooltipSource,
    objectKeysToLowerCase,
    removeDuplicateAoA,
    arrayMergeOnPosition,
    jsonViewerValue,
    isJson,
    convertSeconds,
    tunnelTagObject,
    tunnelTag,
    shaKey,
    radiusAttrNames,
    sortObjectMessage,
    truncateStr,
    setupAttribute,
    sortObjectByKeys,
    constructNameString,
    getStatusColor,
    downloadFile,
    getExpectColor,
    sortObjectSpecial,
    objSortBy,
    filterObjectArray,
    explorerActivation,
    setAndUpdateTable,
    refreshBody,
    getFieldId,
    checkXml,
    checkJSON,
    findInArray,
    getfieldAttrInput,
    getFieldsAttributes,
    getdefaultFormHeader,
    getfieldAttrRow,
    getRandomInt,
    addAdditionalClassToAttributes,
    getAvatarText,
    isObjectArray,
    arrayFindInArray,
    getPlaceholder,
    isHTML,
    getBlobContent,
    fileDirectDownload,
    trimString,
    objectSortByValue,
    getNoDataLabel,
    objectCustomSort,
    convertMs,
    convertMBits,
    convertByte2MB,
    showMbs,
    showSeconds,
    runTest,
    runTestCallBack,
    ipString,
    isEmptyObject,
    safeParseFromStorage,
    getByKey,
    setPropertyByPath,
    parseIntOrDefault,
    capitalize,
    sleep,
    createBlueprint,
    convertFileToBase64,
    base64ToBlob,
    millisecToMicrosec,
    secToMillisec,
    secToMicrosec,
    deepMerge,
    convertToCsv,
};
