import { useStore } from "@/store";

export const restrictByProjId = ({ restrictList = [], allowList = [], redirectTo }) => {
  return (_, __, next) => {
    const store = useStore();
    const id = store.getters["projectId"];

    if (allowList.length) {
      if (allowList.includes(id)) {
        next();
      } else {
        next(redirectTo);
      }
    } else {
      if (restrictList.includes(id)) {
        next(redirectTo);
      } else {
        next();
      }
    }
  };
};
