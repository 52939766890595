const TEST_STATUS_CONFIG = Object.freeze([
  {
    "id": 0,
    "name": "pending",
    "text": "Pending",
    "color": "pending",
    "icon": "mdi-timer-sand",
    "order": 0
  },
  {
    "id": 1,
    "name": "running",
    "text": "Running",
    "color": "testStatusRunning",
    "icon": "mdi-timer-sand",
    "order": 1
  },
  {
    "id": 2,
    "name": "ok",
    "text": "OK",
    "color": "ok",
    "icon": "mdi-check-circle",
    "order": 2
  },
  {
    "id": 3,
    "name": "resultWarning",
    "text": "Result Warning",
    "color": "testStatusWarning",
    "icon": "mdi-alert",
    "order": 7
  },
  {
    "id": 4,
    "name": "resultError",
    "text": "Result Error",
    "color": "testStatusError",
    "icon": "mdi-alert-box",
    "order": 8
  },
  {
    "id": 5,
    "name": "aborted",
    "text": "Aborted",
    "color": "testStatusAborted",
    "icon": "mdi-close-octagon-outline",
    "order": 3
  },
  {
    "id": 6,
    "name": "maxRuntime",
    "text": "Test Timeout",
    "color": "testStatusMaxRuntime",
    "icon": "mdi-progress-clock",
    "order": 4
  },
  {
    "id": 7,
    "name": "processingWarning",
    "text": "Processing Warning",
    "color": "testStatusRuntimeWarning",
    "icon": "mdi-alert",
    "order": 5
  },
  {
    "id": 8,
    "name": "processingError",
    "text": "Processing Error",
    "color": "testStatusRuntimeError",
    "icon": "mdi-alert-box",
    "order": 6
  }
]);

export const NOT_TESTING = {
  id: null,
  name: "notTesting",
  text: "Not Testing",
  color: "notTesting",
  icon: "",
  order: 42
};

export const TEST_STATUS_CONFIG_SHORT_TXT = TEST_STATUS_CONFIG.map((t) => ({
  ...t,
  text: t.text.replace(/Processing/i, "Proc"),
}));

export const TEST_STATUS_MAP_BY_ID_SHORT_TXT = new Map(TEST_STATUS_CONFIG_SHORT_TXT.map(t => [t.id, t]));

export const TEST_STATUS_SHORT_TITLES = TEST_STATUS_CONFIG_SHORT_TXT.map(t => t.text);

export const TEST_STATUS_MAP_BY_ID = new Map(TEST_STATUS_CONFIG.map(t => [t.id, t]));

export const TEST_STATUS_COLORS = TEST_STATUS_CONFIG.map(t => t.color);

export const TEST_STATUS_TITLES = TEST_STATUS_CONFIG.map(t => t.text);

export default TEST_STATUS_CONFIG;
