import Service from '@/js/service.js';
import '@/js/requests';

const service = new Service(`${process.env.VUE_APP_CHART_SERVICE}/chart-service`);

const chartService = {

    postAudit(testStepId,hidden, then, error) {
        service.postRequest(3, 'audit/{test-step-id}', { hidden } ,then, error,testStepId);
    },
    getDashboardList() {
        return new Promise((resolve, reject) => {
            service.getRequest(4, 'dashboard/list', null, resolve, reject);
        });
    },
    getChartById(id, params, then, error) {
        service.getRequest(4, 'chartById/{id}', params, then, error, id);
    },
    getChartByIdPublic(uuid, params, then, error) {
        service.publicGetRequest(3, 'public/chartById/{id}', params, then, error, uuid);
    },
    getExplorerMapByName(name, then, error) {
        service.getRequest(3, 'explorer/map/{name}', null, then, error, name);
    },
    getExternalLink(chartId, then, error) {
        service.getRequest(3, 'link/{id}', null, then, error, chartId);
    },
    createExternalLink(chartId, filter, then, error) {
        service.postRequest(3, 'generate-link', {
            chartId: chartId,
            filter: filter,
        }, then, error);
    },
    deleteExternalLink(chartId, then, error) {
        service.deleteRequest(3, 'link/{id}', null, then, error, chartId);
    },
    getSchedulingResults(scheduledTestId, from, resolution, then, error) {
        service.getRequest(3, 'scheduling-results/{id}', {
            from: from,
            resolution: resolution,
        }, then, error, scheduledTestId);
    },
    createDashboard(dashboard) {
        return new Promise((resolve, reject) => {
          service.putRequest(4, 'dashboard', dashboard, resolve, reject);
        })
    },
    updateDashboard(dashboard) {
        return new Promise((resolve, reject) => {
          service.patchRequest(4, 'dashboard', dashboard, resolve, reject);
        })
    },

    getChartConfigTagKeys(then, error) {
        service.getRequest(3, 'chart-config-tag-keys', null, then, error);
    },
    getChartConfigTagValues(tags, then, error) {
        service.getRequest(3, 'chart-config-tag-values', {
            tags: JSON.stringify(tags),
        }, then, error);
    },
    getTagValues(tag) {
        return new Promise((resolve, reject) => {
            service.getRequest(3, 'tag-value/{tag}', null, resolve, reject, tag);
        })
    },
    getChartConfigMeta(then, error) {
        service.getRequest(3, 'chart-config-meta', null, then, error);
    },
    getChartConfigList(then, error) {
        service.getRequest(3, 'chart-config-list', null, then, error);
    },

    getTemplates(params, then, error) {
        service.getRequest(3, 'templates', params, then, error);
    },
    createTemplate(template, then, error) {
        service.putRequest(3, 'template', template, then, error);
    },
    updateTemplate(template, then, error) {
        service.patchRequest(3, 'template/{id}', template, then, error, template.id);
    },
    deleteTemplate(id, then, error) {
        service.deleteRequest(3, 'template/{id}', null, then, error, id);
    },
    getExplorerDashboard(explorerName, then, error) {
        service.getRequest(4, 'explorer-dashboard', null, then, error, explorerName);
    },
};

export default chartService;
