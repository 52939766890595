<template>
  <v-hover class="search-router-link">
    <template #default="{ hover }">
      <div class="search-router-link__in-box" @click="$emit('click', $event)">
        <v-icon
          v-if="hover"
          class="search-router-link__lens-ico"
          @click="$emit('input', text)"
        >
          mdi-magnify
        </v-icon>

        <router-link :to="to">
          <slot>
            {{ text }}
          </slot>
        </router-link>
      </div>
    </template>
  </v-hover>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  name: "SearchRouterLink",
  props: {
    value: String,
    text: String,
    to: [String, Object],
  },
});
</script>

<style lang="scss">
.search-router-link {
  &__in-box {
    position: relative;
  }

  & &__lens-ico {
    position: absolute;
    left: -24px;
    cursor: pointer;
  }
}
</style>
