<template>
  <div class="pagination-block">
    <XBtn
      v-if="totalCount > 0"
      @click="() => changePage(currentPage - 1)"
      icon="mdi-chevron-left"
      :disabled="currentPage === 1"
    />

    <div
      v-if="totalPagesCount <= MIN_PAGES_FOR_SELECT"
      class="pagination-block__page-buttons"
      ref="pageButtons"
    >
      <div
        v-for="(p, i) of paginationRange"
        :key="i"
        class="pagination-block__button-container"
      >
        <XBtn
          v-if="typeof p === 'number'"
          :text="p"
          class="pagination-block__page-button"
          :color="p === currentPage ? 'primary' : ''"
          @click="() => changePage(p)"
        />

        <div
          v-else
          class="pagination-block__ellipsis"
        >
          {{ p }}
        </div>
      </div>
    </div>

    <XSelect
      v-else-if="totalCount > 0"
      :value="currentPage"
      @input="(page) => changePage(page)"
      :items="Array.from({ length: totalPagesCount }, (_, i) => i + 1)"
      label="Page"
      class="pagination-block__page-select"
      required
    />

    <XBtn
      v-if="totalCount > 0"
      :disabled="currentPage === totalPagesCount"
      @click="() => changePage(currentPage + 1)"
      icon="mdi-chevron-right"
    />
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue"
import XBtn from '@/components/basic/XBtn.vue';
import XSelect from '@/components/basic/XSelect.vue';
import { usePagination } from "@/composition/tables/use-pagination"

const MIN_PAGES_FOR_SELECT = 999;

export default defineComponent({
  name: "PaginationBlock",

  components: {
    XBtn,
    XSelect,
  },

  props: {
    totalCount: {
      type: Number,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },

    itemsPerPage: {
      type: Number,
      required: true,
    },

    siblingCount: {
      type: Number,
      default: 1,
    },
  },

  emits: ["update:current-page"],

  setup(props, { emit }) {
    const {
      paginationRange,
      totalPagesCount
    } = usePagination({
      ...toRefs(props),
    })

    /**
    * @param {number} pageN
    */
    function changePage(pageN) {
      if (pageN < 1 || pageN > totalPagesCount.value) {
        return;
      }

      emit("update:current-page", pageN);
    }

    return {
      paginationRange,
      changePage,
      totalPagesCount,
      MIN_PAGES_FOR_SELECT,
    };
  }
})
</script>

<style lang="scss">
.pagination-block {
  display: flex;
  align-items: center;

  &__page-buttons {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    height: 40px;
    overflow: hidden;
    align-items: center;
    padding: 0 4px;
  }

  &__button-container {
    width: 34px;
    height: 36px;
  }

  &__page-button {
    button.v-btn:not(.v-btn--round).v-size--default {
      min-width: 34px;
      padding: 0 0;
    }
  }

  &__ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 36px;
  }

  &__page-select {
    width: 120px;
  }
}
</style>
